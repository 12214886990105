import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import './Skills.scss'


const Skills = () => {
  const [primary, setPrimary] = useState([])
  const [secondary, setSecondary] = useState([])

  useEffect(() => {
    const primaryQuery = '*[_type == "primary"]'
    const secondaryQuery = '*[_type == "secondary"]'

    client.fetch(primaryQuery)
    .then((data) => {
      setPrimary(data)
    })
    client.fetch(secondaryQuery)
    .then((data) => {
      setSecondary(data)
    })
  }, [])


  return (
    <>
      <h2 className="head-text">My 
        <span> Skillset </span>
      </h2>
      <div className='app__skills-container'>
        <motion.div className='app__skills-list'>
        <h3 className="head-text">Primary</h3>
          <div className="app__skills-icons">
            {primary.map((skill) => (
              <motion.div
                whileInView={{opacity: [0, 1]}}
                transition={{ duration: 0.5 }}
                className='app__skills-item app__flex'
                key={skill.name}
              >
                <div className="app__flex" style={{ backgroundColor: skill.bgColor}}>
                  <img src={urlFor(skill.icon)} alt={skill.name} />
                </div>
                <p className="p-text">{skill.name}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
        <motion.div>
        <h3 className="head-text">Secondary</h3>
        <div className="app__skills-icons">
          {secondary.map((skill) => (
            <motion.div
              whileInView={{opacity: [0, 1]}}
              transition={{ duration: 0.5 }}
              className='app__skills-item app__flex'
              key={skill.name}
            >
              <div className="app__flex" style={{ backgroundColor: skill.bgColor}}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </div>
        </motion.div>
      </div>
    </>
  )
}


export default AppWrap(MotionWrap(Skills, 'app__skills'), 'skills', 'app__whitebg');