import React, { useState } from 'react'
import './Footer.scss'
import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
import { client } from '../../client'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData

  const handleChangeInput = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {
    setLoading(true)

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message,
    }

    client.create(contact)
    .then(() => {
      setLoading(false)
      setIsFormSubmitted(true)
    })
  }

  return (
    <>
      <h2 className="head-text">Take a coffe  
        <span> & chat with me </span>
      </h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:marioarro186@gmail.com" className='p-text'>marioarro186@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tel: +371 29998713" className='p-text'>+371 29998713</a>
        </div>
      </div>

      {!isFormSubmitted ? 
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input type="text" name='name' className="p-text" placeholder='Your Name' value={name} onChange={handleChangeInput}/>
          </div>
          <div className="app__flex">
            <input type="email" name='email' className="p-text" placeholder='Your Email' value={email} onChange={handleChangeInput} />  
          </div>
          <div>
            <textarea className='p-text' placeholder='Your Message' name='message' value={message} onChange={handleChangeInput}></textarea>
            <button className="p-text" type='button' onClick={handleSubmit}>{loading ? 'Sending . . .' : 'Send Message'}</button>
          </div>
        </div>
        :
        <div>
          <h3 className="head-text">Thank you for getting in touch!</h3>
        </div>
      }
      <div className="copyright">
        <p className="p-text">@2022 Mario Arro</p>
        <p className="p-text">All rights reserved</p>
    </div>
    </>
  )
}

export default AppWrap(MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__greybg'
)