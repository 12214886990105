import React, { useState, useEffect } from 'react'
import './About.scss'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { urlFor, client } from '../../client'
import { AppWrap, MotionWrap } from '../../wrapper'

const About = () => {
  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    const query = '*[_type == "abouts"]'

    client.fetch(query)
    .then((data) => {
      setAbouts(data)
    })
  }, [])
  

  return (
    <>
      <h2 className="head-text">About 
      <span> Me </span>
      </h2>
      <p className="p-about-me">I am a web developer that is really passionate about coding and I am always looking for opportunities to grow and get better. Strong sense of responsibility makes the work that I do accurate and reliable and it always makes me finish the projects that I am working on. As well as planning and scheduling the day makes it more productive and helps me to be right on track.</p>
      <br />
      <p className="p-about-me">In free time I like to be as creative and productive as possible, while learning new skills like video editing, music making, photo editing etc. and I must include sports as well into my routine like going to the gym, playing beach volleyball, basketball, soccer or even going for a walk, because otherwise I will lose my mind if I sit all day long.</p>
      <p className="p-about-me moto-text">And I live by the moto, what makes you uncomfortable, makes you better.</p>
    </>
    )
}

export default AppWrap(MotionWrap(About, 'app__about'), 'about', 'app__whitebg');

// <div className="app__profiles">
// {abouts.map((about, index) => (
//   <motion.div
//     whileInView={{ opacity: 1 }}
//     whileHover={{ scale: 1.1 }}
//     transition={{ duration: 0.5, type:'tween' }}
//     className="app__profile-item"
//     key={about.title + index}
//     >
//     <img src={urlFor(about.imgUrl)} alt={about.title} />
//       <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
//       <p className="p-text" style={{ marginTop: 20 }}>{about.description}</p>
//   </motion.div>
// ))}
// </div>