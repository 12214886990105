import React from 'react'
import { BsInstagram, BsLinkedin} from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <a href='https://www.linkedin.com/in/leonardo-mario-arro-1099bb20b/'>
            <BsLinkedin />
        </a>
        <a href='https://www.facebook.com/mario.arro.79/'>
            <FaFacebookF href='' />
        </a>
        <a href='https://www.instagram.com/mario_arro/'>
            <BsInstagram href='' />
        </a>
    </div>
  )
}

export default SocialMedia