import React from 'react'
import './Header.scss'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { AppWrap } from '../../wrapper'

const scaleVariants = {
  whileInView:{
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div id='home' className='app__header app__flex'>
      <motion.div
      whileInView={{ x:[-100, 0], opacity: [0, 1]}}
      transition={{ duration: 1 }}
      className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋🏽</span>
            <div style={{ marginLeft: 20 }}>
              <p className='p-text intro-text'>Hello, my name is</p>
              <h1 className='head-text'>Mario</h1>
              <p className='p-text intro-text'>and I am a</p>
            </div>
          </div>
          <div className="tag-cmp app__flex">
          <p className='p-text intro-text'>Web Developer</p>
          </div>
        </div>
      </motion.div>
      
      <motion.div
      whileInView={{ opacity: [0, 1]}}
      transition={{ duration: 1, delayChildren: 0.5 }}
      className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
      </motion.div>
      <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles">
        {[{name: 'TypeScript', image: images.typescript}, {name: 'React', image: images.react}, {name: 'Sass', image: images.sass}].map((circle, index) => (
          <div>
            <div className='circle-cmp app__flex' > 
              <img src={circle.image} alt="circle" />
            </div>
            <p className='p-text circle-name' key={circle.name}>{circle.name}</p> 
          </div>
        ))}
      </motion.div>
    
    </div>
  )
}

export default AppWrap(Header, 'home') 

// <motion.img
// whileInView={{ opacity: [0, 1]}}
// transition={{ duration: 1, ease:'easeOut' }}
// src={images.circle}
// alt="profile_circle"
// className="overlay_circle"
// />